<template>
  <div>
    <b-overlay :show="loading">

    <div class="w-100 d-flex justify-content-end">
      <b-button variant="primary" class="mb-1" @click="handleAdd">
        <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
      </b-button>
    </div>
    <b-card>
      <b-table :fields="fields" :items="items" responsive :busy="busy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>
    </b-overlay>

    <b-modal hide-footer v-model="formModalActive" no-close-on-backdrop>
      <ExpenseForm :id="id" @closeModal="closeModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import ExpenseForm from '@/views/directories/expense-type/components/ExpenseForm'
  import {
    getExpenceTypes,
    deleteExpenceTypes
  } from '@/api/expence-types/expence-types'

  export default {
    name: 'Index',
    components: {
      ExpenseForm
    },
    data() {
      return {
        id: null,
        items: [],
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'name',
            label: this.$t('message.name_title')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        formModalActive: false,
        busy: true,
        loading: false,
      }
    },
    created() {
      this.fetchExpenses()
    },
    methods: {
      fetchExpenses() {
        this.loading = true;
        getExpenceTypes(this.pagination).then(response => {
          this.items = response.data.data
          this.total = response.data.data;
          this.busy = false;
          this.loading = false;
        })
      },
      handleAdd() {
        this.id = null;
        this.formModalActive = true
      },
      handleEdit(id) {
        this.id = id;
        this.formModalActive = true
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchExpenses()
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteExpenceTypes(id).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchExpenses()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
    }
  }
</script>